import { MEDIA_URL } from "../config/host"
import { IEntry } from "../model"

export function isEmptyObject(obj: any): boolean {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

export function createSlug(text: string) {
    const low = text.toLocaleLowerCase()
    return low.split(' ').join('-')
}

export function getText(text: string) {
    return text
}

export function assembleMediaUrl(path: string) {
    return MEDIA_URL+'/'+path
}

export const sortWinners = (entries: IEntry[]) => {
    if (entries.length === 3 && entries.find(e => e.is_winner_peoples_choice)) {
        const res: IEntry[] = [entries[0], entries[1], entries[2]]
        entries.forEach(e => {
            if (e.is_winner_gold) {
                res.splice(0, 1, e)
            } else if (e.is_winner_silver) {
                res.splice(1, 1, e)
            } else {
                res.splice(2, 1, e)
            }
        })
        return res
    }
    return entries.sort((a,b) => {
        if (a.is_winner_gold) return -1
        if (a.is_winner_silver) return 1
        return 0
    })
}